<template>
  <div class="modal-confirm modal-employee-stopped">
    <div class="modal-confirm__body">
      <div class="modal-confirm__title">
        <!--        Сотрудник прекратил с вами сотрудничество,-->
        <!--        вышел из организации-->
        {{ title }}
      </div>
      <div class="modal-employee__body">
        <img v-if="photo" class="employees-avatar" :src="photo" alt="">
        <img v-else class="employees-avatar" :src="require('@img/user-avatar.svg')" alt="">
        <div class="employees-content">
          <p class="employee-name">{{ name }}</p>
        </div>
      </div>
    </div>
    <div class="modal-confirm__footer modal-employee__footer">
      <button
        type="button"
        class="btn btn--prime"
        @click="confirm"
      >ОК</button>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    title: String,
    name: String,
    photo: String,
    position: String,
    resolve: Function
  },
  methods: {
    confirm() {
      this.$emit('close');
      this.resolve(true);
    }
  }
};
</script>

<style lang="scss" scoped>

@import "@style/src/component/modal-confirm-styles.scss";

:root {
  --color-prime: #0C76DA;
  --color-prime-active: #0F4578;
}

.theme--red {
  --color-prime: #FF6B69;
  --color-prime-active: #D7221F;
}

.modal-employee-stopped {
  width: 100%;
  @include respond-to('md') {
    max-width: 400px;
    //TODO
    margin: auto;
  }
  .modal-employee__body {
    display: flex;
    align-items: center;
    background: #FFFFFF;
    border: 1px solid rgba(118, 118, 128, 0.2);
    border-radius: 8px;
    padding: 10px;
  }

  .modal-employee__title {
    font-weight: 600;
    font-size: 19px;
    color: #000000;
    text-align: center;
  }

  .employees-avatar {
    width: 74px;
    height: 74px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 10px;
  }

  .employee-name {
    font-weight: 600;
    font-size: 15px;
    color: #020202;
    margin-bottom: 5px;
  }

  .employee-desc {
    font-size: 15px;
    color: rgba(0, 0, 0, 0.4);
  }

  .btn {
    width: 100%;
    background: var(--color-prime);
    &:hover, &:active {
      background: var(--color-prime-active);
    }
  }

  .modal-employee__footer {
    padding: 24px 15px;
  }
}


</style>
